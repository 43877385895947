import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ElectronService } from 'ngx-electron';
import { DataShareService } from '../../services/data-share.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  tab = 1;

  language;
  region = "ca";

  buyKey1 = "";
  buyKey2 = "";
  sellKey1 = "";
  sellKey2 = "";
  closeKey1 = "";
  closeKey2 = "";
  insertKey1 = "";
  insertKey2 = "";
  deleteKey1 = "";
  deleteKey2 = "";

  email;
  switch;

  constructor(
    private electronService: ElectronService, 
    private translate: TranslateService,
    private dataShareService: DataShareService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('currentLang') || 'en';
    this.region = localStorage.getItem('region');
    this.email = JSON.parse(localStorage.getItem('auth_user')).email;
    this.switch = localStorage.getItem("notificationEnabled") === "true" ? true : false;

    // init the saved hotkeys config
    this.initHotKeys();

    // params
    this.route.paramMap.subscribe(params => {
      if(params.get("id")) {
        this.tab = parseInt(params.get("id")) || 1;
      }
    })
  }

  changeLanguage = () => {  
    localStorage.setItem("currentLang", this.language);
    this.translate.setDefaultLang(this.language);
  }

  selectTab(id){
    this.tab = id;
  }

  activeTab(num){
    return this.tab === num;
  }

  saveGeneralSettings(isExit) {
    localStorage.setItem("region", this.region);
    this.dataShareService.showAlert({title: 'Success', body: 'Setting successfully saved!'});
    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

  saveHotkeysSettings(isExit) {

    let hotKeys = {
      buy: this.buyKey1.toLowerCase()+'+'+this.buyKey2.toLowerCase(),
      sell: this.sellKey1.toLowerCase()+'+'+this.sellKey2.toLowerCase(),
      close: this.closeKey1.toLowerCase()+'+'+this.closeKey2.toLowerCase(),
      insert: this.insertKey1.toLowerCase()+'+'+this.insertKey2.toLowerCase(),
      delete: this.deleteKey1.toLowerCase()+'+'+this.deleteKey2.toLowerCase()
    }

    if(!this.validateHotKeys(hotKeys)) {
      return;
    }

    localStorage.setItem('hotKeys', JSON.stringify(hotKeys));
    this.dataShareService.showAlert({title: 'Success', body: 'Setting successfully saved!'});

    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

  saveLockSettings(isExit) {
    this.dataShareService.showAlert({title: 'Success', body: 'Setting successfully saved!'});
    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

  saveNotificationSettings(isExit) {
    localStorage.setItem("notificationEnabled", this.switch.toString());
    this.dataShareService.showAlert({title: 'Success', body: 'Setting successfully saved!'});
    if(isExit) {   
      this.router.navigate(['/dashboard']);
    }
  }

  saveOrderSettings(isExit) {
    this.dataShareService.showAlert({title: 'Success', body: 'Setting successfully saved!'});
    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

  validateHotKeys(hotKeys) { 
    if(
      this.buyKey1 && !this.buyKey2 || this.buyKey2 && !this.buyKey1 ||
      this.sellKey1 && !this.sellKey2 || this.sellKey2 && !this.sellKey1 ||
      this.closeKey1 && !this.closeKey2 || this.closeKey2 && !this.closeKey1 || 
      this.insertKey1 && !this.insertKey2 || this.insertKey2 && !this.insertKey1 ||
      this.deleteKey1 && !this.deleteKey2 || this.deleteKey2 && !this.deleteKey1
    ) {
      this.dataShareService.showAlert({title: 'Error', body: 'Kindly select valid combinations for hotkeys!'});
      return false;
    }

    let hotkeyVal = Object.values(hotKeys);
    console.log(hotkeyVal);

    for(var i = 0; i < hotkeyVal.length; i++) {
      for(var j = i+1 ; j < hotkeyVal.length; j++) {
        if(hotkeyVal[i] != "+" && hotkeyVal[i] == hotkeyVal[j]) {
          this.dataShareService.showAlert({title: 'Error', body: 'Please choose unique hoykey combination for all the operations.'});
          return false;
        }
      }
    }

    return true;
  }

  initHotKeys() {
    let hotkeys = JSON.parse(localStorage.getItem("hotKeys"));
    if(hotkeys) {
      this.buyKey1 = hotkeys.buy.split("+")[0];
      this.buyKey2 = hotkeys.buy.split("+")[1];
      this.sellKey1 = hotkeys.sell.split("+")[0];
      this.sellKey2 = hotkeys.sell.split("+")[1];
      this.closeKey1 = hotkeys.close.split("+")[0];
      this.closeKey2 = hotkeys.close.split("+")[1];
      this.insertKey1 = hotkeys.insert.split("+")[0];
      this.insertKey2 = hotkeys.insert.split("+")[1];
      this.deleteKey1 = hotkeys.delete.split("+")[0];
      this.deleteKey2 = hotkeys.delete.split("+")[1];
    }
  }
}
