import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../services/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

    constructor(private logger: NGXLogger, private authService: AuthService) {}

    create() {
      let auth_token = this.authService.loadToken();
      this.logger.setCustomHttpHeaders(new HttpHeaders({'Authorization': auth_token}))
      return this.logger;
    }
}
