import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { TourGuideService } from '../../services/tour-guide.service';

declare var $ :any;

@Component({
  selector: 'app-getstarted',
  templateUrl: './getstarted.component.html',
  styleUrls: ['./getstarted.component.css']
})
export class GetstartedComponent implements OnInit {

  user;
  openLink: Function;

  constructor(private dataShareService: DataShareService, private tourGuideService: TourGuideService) { }

  ngOnInit() {
    this.openLink = this.dataShareService.openLink;
  
    this.dataShareService.notice.subscribe((data) => {
      this.user = JSON.parse(localStorage.getItem("auth_user"));

      if(data.show) {
        document.getElementById("getstartedbtn").click();
      }
    });
  }

  openWalletModal() {
    $("#getstarted").modal('hide');
    document.getElementById('createWallet').click();
  }

  startTour(section) {
    $("#getstarted").modal('hide');
    this.tourGuideService.start(section);
  }

}
