import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { TradingService } from '../../services/trading.service';
import { DataShareService } from '../../services/data-share.service';
import { NGXLogger } from 'ngx-logger';
import { Socket } from 'ngx-socket-io';
import { MobileViewService } from '../../services/mobile-view.service'; // Adjust the path as necessary


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // logger
  logger: NGXLogger;
  
  openLink: Function;
  
  constructor(
    private authService: AuthService, 
    private router: Router,
    private accountService: AccountService,
    private tradingService: TradingService,
    private dataShareService: DataShareService,
    private socket: Socket,
    public mobileViewService: MobileViewService
  ) {
    this.openLink = dataShareService.openLink;
  }
  
  accountid;
  walletname = "";
  password = "";
  cpassword = "";
  isConnected;
  
  ngOnInit() {

    this.applyMobileView();

    if(localStorage.getItem("socketStatus") == "connected")
      this.isConnected = true;

    if(localStorage.getItem("socketStatus") == "disconnected")
      this.isConnected = false;

    this.accountid = JSON.parse(localStorage.getItem("auth_user")).accountid;

    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;


    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }

    this.socket.on('connect', () => {
      this.isConnected = true;
      localStorage.setItem("socketStatus", "connected");
    })

    this.socket.on('disconnect', () => {
      this.isConnected = false;
      localStorage.setItem("socketStatus", "disconnected");
    })

  }

  applyMobileView() {
    const container = document.querySelector('.container');
    if (this.mobileViewService.isMobileViewEnabled()) {
      console.log('Mobile view enabled header:', this.mobileViewService.isMobileViewEnabled());
      //container.classList.add('mobile-view');
    } else {
      console.log("Mobile Disable")
      //container.classList.remove('mobile-view');
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  createWallet() {

    // input validation
    if(this.walletname.trim().length == 0 || this.password.trim().length == 0) {
      this.dataShareService.showAlert({title: 'Error', body: 'all fields are mandatory for wallet creation!'});
      return;
    }

    if(this.password != this.cpassword) {
      this.dataShareService.showAlert({title: 'Error', body: 'Passwords do not match! Please try again.'});
      return;
    }

    // check if wallet name exists
    this.accountService.walletInfo().subscribe(data => {
      if(data.error) {
        this.logger.error('Error while loading wallet info!', data);
      } else {

        for(let i = 0; i < data.length; i++) {
          if(data[i].wallet_name.toLowerCase() == this.walletname.toLowerCase()) {
            console.log('Found duplicate wallet name.')
            this.dataShareService.showAlert({title: 'Error', body: 'Wallet name exists. Please use different wallet name.'});
            // reset model vars
            this.resetModal();
            return;
          }
        } 

        // send request to create wallet
        let wallet = {
          accountid: this.accountid,
          password: this.password,
          walletname: this.walletname  
        }

        console.log('Creating test walllet' , wallet)
        this.tradingService.createWallet(wallet);

        // reset model vars
        this.resetModal();
      }
    })

  }

  logout = () => {
    let rows = JSON.parse(localStorage.getItem("rows"));
    const hasAutoTrading = rows.filter(row => row.lastOrderType === "Auto Trading").length > 0 ? true : false;
    
    if(hasAutoTrading) {
      // show agreement
      document.getElementById("logout-agreementbtn").click();
    } else {
      this.onLogout();
    }
  }

  onLogout = async () => {
    await this.tradingService.stopSignalOnClose();
    this.socket.disconnect();
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  resetModal = () => {
    this.walletname = "";
    this.password = "";
    this.cpassword = "";
  }

}
