import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service'; 
import { NGXLogger } from 'ngx-logger';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class TradingService {

  // logger
  logger: NGXLogger;
  constructor(
    private http: Http, 
    private socket: Socket, 
    private accountService: AccountService,
    private LoggerService: LoggerService
  ) { 
    this.logger = this.LoggerService.create();
  }

  getAIAdvice = (data) => {
    // send ai advice data
    this.socket.emit("advice-get", data);
  }

  stopAIAdvice = (data) => {
    // send ai advice data
    this.socket.emit("advice-stop", data);
  }

  createWallet = (data) => {
    console.log('trading service last')
    this.socket.emit("wallet-create", data);
  }

  getAutoTradingOrders = () => {
    this.socket.emit("at-get-all");
  }

  getDayTradingOrders = () => {
    this.socket.emit("dt-get-all");
  }

  getTradeHistory = (wallet_id) => {
    this.socket.emit("history-portfolio", {wallet_id: wallet_id});
  }

  fetchStockPrice = (exchange, symbol) => {
    let headers = new Headers();
    headers.append('Content-Type','application/json');

    return new Promise((resolve, reject) => {
      // Canada - Market
      if(exchange == 'TSE') {
        let url = 'https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol='+symbol+'.TO&interval=1min&apikey=1573O017HYX3IDFW';
        console.log('Getting current price:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.json()))
        .subscribe(res => {
          if(res['Error Message']) {
            reject(res['Error Message']);
          }
          else {
            resolve(Object.values(res["Time Series (1min)"])[0]["4. close"]);
          }    
        }, (err) => {
          reject(err);
        });
      }

      // US - Market
      if(exchange == 'NYSE' || exchange == 'NASDAQ') {
        let url = 'https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol='+symbol+'&interval=1min&apikey=1573O017HYX3IDFW';
        console.log('Getting current price:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.json()))
        .subscribe(res => {
          if(res['Error Message']) {
            resolve(null);
          }
          else {
            resolve(Object.values(res["Time Series (1min)"])[0]["4. close"]);
          }  
        }, (err) => {
          resolve(null);
        });
      }

      // China - Market
      if(exchange == 'SHE' || exchange == 'SHA') {
        let url = 'http://35.182.89.96:8082/'+exchange+'/'+symbol;
        console.log('Getting current price:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.text()))
        .subscribe(res => {
          let data = res.split(" ");
          
          if(parseInt(data[data.length - 2])) {
            resolve(data[data.length - 2]);
          } else {
            reject(null);
          }
        }, (err) => {
          reject(err);
        });
      }

      // Hongkong - Market
      if(exchange == 'SEHK') {
        let url = 'http://35.182.89.96:8082/SEHK/'+symbol;
        console.log('Getting current price:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.text()))
        .subscribe(res => {
          let data = res.split("~");

          if(data.length <= 1 || !data[4]) {
            reject(null);
          } else {
            resolve(data[4]);
          }
        }, (err) => {
          reject(err);
        });
      }

    });
    
    
  }

  fetchStockPriceChange = (exchange, symbol) => {
    let headers = new Headers();
    headers.append('Content-Type','application/json');

    return new Promise((resolve, reject) => {
      // Canada - Market
      if(exchange == 'TSE') {
        let url = 'https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol='+symbol+'.TO&apikey=1573O017HYX3IDFW';
        console.log('Getting price change:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.json()))
        .subscribe(res => {
          resolve(res["Global Quote"]["10. change percent"].split("%")[0]); 
        }, (err) => {
          reject(err);
        });
      }

      // US - Market
      else if(exchange == 'NYSE' || exchange == 'NASDAQ') {
        let url = 'https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol='+symbol+'&apikey=1573O017HYX3IDFW';
        console.log('Getting price change:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.json()))
        .subscribe(res => {
          resolve(res["Global Quote"]["10. change percent"].split("%")[0]);  
        }, (err) => {
          reject(err);
        });
      }

      else if(exchange == 'SHA' || exchange == 'SHE') {
        let url = 'http://35.182.89.96:8082/SHA-SHE/'+symbol;
        console.log('Getting price change:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.json().datatable.data))
        .subscribe(res => {
            if(res < 2) {
              reject(null);
            } else {
              let currentClose = res[res.length - 1][2];
              let prevoiusClose = res[res.length - 2][2];
              let change = (((currentClose - prevoiusClose) / prevoiusClose) * 100).toFixed(3);
              resolve(change);
            }
        }, (err) => {
          reject(err);
        });
      }
      // Hongkong - Market
      else if(exchange == 'SEHK') {
        let url = 'http://35.182.89.96:8082/SEHK/'+symbol;
        console.log('Getting price change:', url);
        this.http.get(url, {headers:headers})
        .pipe(map(result => result.text()))
        .subscribe(res => {
          let data = res.split("~");

          if(data.length <= 1 || !data[4]) {
            reject(null);
          } else {
            resolve(data[8]);
          }
        }, (err) => {
          reject(err);
        });
      }

      else {
        reject(null);
      }
      
    });
    
    
  }

  stopSignalOnClose = async () => {
    var userid = JSON.parse(localStorage.getItem("auth_user")).id;
    var rows = JSON.parse(localStorage.getItem("rows"));

    for(let i = 0; i < rows.length; i++) {
      // Stop only for non-Auto Trading rows
      if(rows[i].symbol && rows[i].lastOrderType != "Auto Trading") {
        let data = {
          userid: userid,
          symbol: rows[i].symbol,
          exchange: rows[i].exchange,
          ai_status: "4"
        }
    
        console.log('Stopping AI Advice for: '+ data.symbol);

        await new Promise((resolve, reject) => {
          this.stopAIAdvice(data);
          resolve();
        })
        .catch(error => {
          console.log('Error while stopping AI Advice: ', error);
        });
        
        // Reset the row data
        // rows[i].aiStatus = "4";
        // rows[i].wallet_address = "";
        // rows[i].wallet_name = "";
        // rows[i].lastOrderType = "";
        // rows[i].signalCount = 0;
        // rows[i].volume = 0;
        // rows[i].capital = 0;

        // // Update the row
        // localStorage.setItem("rows", JSON.stringify(rows));
      }
    }

    // resolve at the end
    return new Promise((resolve, reject) => {
      resolve();
    })
  
  }

  forexConversion = (exchange, amount, toUSD) => {
    let headers = new Headers();
    headers.append('Content-Type','application/json');

    return new Promise((resolve, reject) => {
      var url
      // Canada - Market
      if(exchange == 'TSE') {
        url = 'https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=USD&to_currency=CAD&apikey=1573O017HYX3IDFW';
      }

      // US - Market
      if(exchange == 'NYSE' || exchange == 'NASDAQ') {
        return resolve(amount.toFixed(2));
      }

      // China - Market
      if(exchange == 'SHE' || exchange == 'SHA') {
        url = 'https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=USD&to_currency=CNH&apikey=1573O017HYX3IDFW';
      }

      // Hongkong - Market
      if(exchange == 'SEHK') {
        url = 'https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=USD&to_currency=HKD&apikey=1573O017HYX3IDFW';
      }

      this.http.get(url, {headers:headers})
      .pipe(map(result => result.json()))
      .subscribe(res => {
        let exchangeRate = res["Realtime Currency Exchange Rate"]["5. Exchange Rate"];

        if(toUSD) {
          this.logger.info('Getting Forex rate:'+ url, "Exchange:", exchange, "amount:", amount, "Exchanged amount (to USD):", (amount / exchangeRate).toFixed(2));
          resolve((amount / exchangeRate).toFixed(2));
        } else {
          this.logger.info('Getting Forex rate:'+ url, "Exchange:", exchange, "amount:", amount, "Exchanged amount (from USD):", (amount * exchangeRate).toFixed(2));
          resolve((amount * exchangeRate).toFixed(2));
        }
        
      }, (err) => {
        reject(err);
      });

    });
    
    
  }

  fetchLotSize = (exchange, symbol) => {

    return new Promise((resolve, reject) => {

      // Chinese Market
      if(exchange == 'SHE' || exchange == 'SHA') {
        resolve(100);
      }

      // Hongkong - Market
      else if(exchange == 'SEHK') {
        let url = 'http://35.182.89.96:8082/SEHK-SLOT/'+symbol;

        console.log('Getting SEHK Lot Size:', url);
        this.http.get(url)
        .pipe(map(result => result.json()))
        .subscribe(res => {
          if(res.error == "not_found") {
            reject('Could not find the lot size for ' + symbol);
          } else {
            resolve(res.size)
          }
        }, (err) => {
          reject(err);
        });
      }

      else {
        reject('Invalid exchange: '+ exchange);
      }

    });
    
  }

  calculateNetPosition = (symbol) => {
    return new Promise((resolve, reject) => {
      this.accountService.history().subscribe(history => {

        // filter MKT order history
        var orders = history.filter(row => row.symbol === symbol && row.action && row.volume).sort((a: any, b: any) => {
          return new Date(b.createtime).getTime() - new Date(a.createtime).getTime();
        });
  
        console.log(symbol, 'history:', orders);
  
        var netPosition = 0;
        if(orders.length > 0) {

          for(var i = 0; i < orders.length; i++) {
            if(orders[i].action == "BUY") {
              netPosition = netPosition + orders[i].volume;
            }
            if(orders[i].action == "SELL") {
              netPosition = netPosition - orders[i].volume;
            }
          }

          return resolve(netPosition);

        } else {
          resolve(netPosition);
        } 
      })
    })
  }
  
}
