import { Component, OnInit, Input } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() color = '#fff';
  @Input() bgColor = '#fff';
  @Input() size = '';

  loadingText;
  show = false;

  constructor(private dataShareService: DataShareService) { }

  ngOnInit() {
    this.dataShareService.spinner.subscribe(data => {
      this.show = data.show;
      this.loadingText = data.loadingText;
    })
  }
  
}
