import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  title;
  body;
  param;

  constructor(private dataShareService: DataShareService) { }

  ngOnInit() {
    this.dataShareService.alert.subscribe(data => {
      if(data.body) {
        this.title = data.title;
        this.body = data.body;
        this.param = data.translationParams ? data.translationParams: {};
        document.getElementById("alertbtn").click();
      }
    })
  }

}
