import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css']
})
export class ManageAccountComponent implements OnInit {

 
  tab = 1;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  selectTab(id){
    this.tab = id;
  }

  activeTab(num){
    return this.tab === num;
  }

  updateProfile(isExit) {
    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

  updatePassword(isExit) {
    if(isExit) {
      this.router.navigate(['/dashboard']);
    }
  }

}
