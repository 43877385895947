import { Component, OnInit, OnDestroy } from '@angular/core';
import { TradingService } from '../../services/trading.service';
import { AccountService } from '../../services/account.service';
import { Socket } from 'ngx-socket-io';
import { resolve } from 'dns';
import { reject } from 'q';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit, OnDestroy {

  wallets;
  wallet_id;
  data = [];

  constructor(
    private tradingService: TradingService,
    private accountService: AccountService,
    private socket: Socket
  ) { }

  ngOnInit() {
    this.wallets = JSON.parse(localStorage.getItem("wallets"));

    this.socket.on('history-portfolio', (data) => {

      if(data && data.length) {
        console.log('received history/portfolio: ', data);
        this.processData(data);
      } else {
        console.log('No trade history found for portfolio.');
      }

    });
  }

  ngOnDestroy() {
    this.socket.removeAllListeners('history-portfolio');
  }

  getData() {
    if(this.wallet_id) {      
      console.log('getting portfolio for wallet_id:'+this.wallet_id);
      this.tradingService.getTradeHistory(this.wallet_id);
    } else {
      console.log('Wallet not selected. couldn\'t find wallet id');
    }
  }

  getLocalData() {
    var portfolio = JSON.parse(localStorage.getItem("portfolio"));
    
    for(let i=0; i < portfolio.length; i++) {
      if(portfolio[i].wallet_id == this.wallet_id) {
        console.log('Getting local copy of portfolio for wallet_id:'+this.wallet_id);
        this.data = portfolio[i].data;
        return;
      }
    }

    this.data = [];
  }

  fetchSymbolPosition(sym, wallet_address) {
    return new Promise((resolve, reject) => { 
      this.accountService.getPosition(sym, wallet_address).subscribe(res => {
        resolve(res.balance);
      }, error => {
        reject(error);
      });

    })
  }

  async processData(data) {

    this.data = []; // empty prev data
    let symbols =  Array.from(new Set(data.map(data => data.symbol)).values());
    let wallet_address = JSON.parse(localStorage.getItem("wallets")).filter(wallet => wallet.id == data[0].wallet_id)[0].wallethash; 
    // console.log('Symbols:' , symbols.length)

    for(let i = 0; i < symbols.length; i++ ) {
      // Filter MKT order by symbol and sort asc
      var orders = data.filter(row => row.symbol === symbols[i] && row.action && row.volume).sort((a, b) => {
        return (a.id - b.id); 
      });

      // console.log(symbols[i], 'history:', orders);

      var netPosition = 0;

      if(orders.length > 0) {

        for(var j = 0; j < orders.length; j++) {
          if (orders[j].ai_status != null) {
            //console.log('Current Ai Status:' , orders[j].ai_status)
            console.log('')
          }
          if(orders[j].action == "BUY") {
            netPosition = netPosition + orders[j].volume;
          }
          if(orders[j].action == "SELL") {
            netPosition = netPosition - orders[j].volume;
          }
        }
      }

      // skip if position 0
      if(netPosition === 0) {
        continue;
      }

      // get current price
      let currentPrice: any = await this.tradingService.fetchStockPrice(orders[orders.length - 1].exchange, symbols[i]);

      if (currentPrice === null) {
        continue;
      }

      this.data.push({
        symbol: symbols[i],
        avg_price: orders[orders.length - 1].avg_fill_price,
        position: netPosition,
        mkt_value: currentPrice * netPosition
      });
    }

    this.updateLocalData({
      wallet_id: data[0].wallet_id,
      data: this.data
    });
       
  }

  updateLocalData(portfolioData) {
    console.log('Confirm data:' , portfolioData)
    var portfolio = JSON.parse(localStorage.getItem("portfolio"));

    // if data exists
    if(portfolio.length > 0) {
      for(let i=0; i < portfolio.length; i++) {  
        if(portfolio[i].wallet_id == portfolioData.wallet_id) {
          // update data if exists
          portfolio[i].data = portfolioData.data; 
          localStorage.setItem("portfolio", JSON.stringify(portfolio));
          return;  
        }
      }
      // push data if doesn't exist
      portfolio.push(portfolioData);
    }
    else {
      portfolio.push(portfolioData);
    }

    localStorage.setItem("portfolio", JSON.stringify(portfolio));
  }

}
