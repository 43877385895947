import { Component, OnInit, OnDestroy } from '@angular/core';
import { TradingService } from '../../services/trading.service';
import { AccountService } from '../../services/account.service';
import { Socket } from 'ngx-socket-io';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, OnDestroy {

  public data;
  userid;
  wallets;
  wallet_id;

  constructor(
    private tradingService: TradingService,
    private accountService: AccountService,
    private socket: Socket
  ) { }

  ngOnInit() {
    this.userid = JSON.parse(localStorage.getItem("auth_user")).id;
    this.wallets = JSON.parse(localStorage.getItem("wallets"));

    this.socket.on('history-portfolio', (data) => {
      console.log('received history/portfolio: ', data);

      if(data && data.length) {
        this.data = data;
        let history = {
          wallet_id: data[0].wallet_id,
          data: data
        }
  
        this.updateLocalData(history);
      } else {
        console.log('No trade history found.');
      }

     
    });
  }

  ngOnDestroy() {
    this.socket.removeAllListeners('history-portfolio');
  }

  getData() {
    if(this.wallet_id) {      
      console.log('getting history ..')
      this.tradingService.getTradeHistory(this.wallet_id);
    } else {
      console.log('Wallet not selected. couldn\'t find wallet id');
    }
  }

  getLocalData() {
    var history = JSON.parse(localStorage.getItem("history"));
    
    for(let i=0; i < history.length; i++) {
      if(history[i].wallet_id == this.wallet_id) {
        console.log('Getting local copy of history for wallet_id:'+this.wallet_id);
        this.data = history[i].data;
        return;
      }
    }

    this.data = [];
  }

  updateLocalData(historyData) {
    var history = JSON.parse(localStorage.getItem("history"));

    // if data exists
    if(history.length > 0) {
      for(let i=0; i < history.length; i++) {  
        if(history[i].wallet_id == historyData.wallet_id) {
          // update data if exists
          history[i].data = historyData.data; 
          localStorage.setItem("history", JSON.stringify(history));
          return;  
        }
      }
      // push data if doesn't exist
      history.push(historyData);
    }
    else {
      history.push(historyData);
    }
    
    localStorage.setItem("history", JSON.stringify(history)); 
  }

}
