import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { DataShareService } from '../../services/data-share.service';
import { MobileViewService } from '../../services/mobile-view.service'; // Adjust the path as necessary


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  
  // auth token
  auth_token = "";

  showOTPInput = false;

  // model vars
  username = "";
  password = "";
  cpassword = "";
  email = "";
  verificationType = "";
  OTP = "";
  mobile = "";
  iti;


  constructor(
    private translate: TranslateService, 
    private accountService: AccountService,
    private router: Router,
    private authService: AuthService,
    private dataShareService: DataShareService,
    private mobileViewService: MobileViewService

  ) { }

  ngOnInit() {
    this.applyMobileView();
    if(this.authService.loggedIn()){
      this.router.navigate(['/dashboard']);
    }
  }

  applyMobileView() {
    const container = document.querySelector('.container');
    if (this.mobileViewService.isMobileViewEnabled()) {
      console.log('Mobile view enabled:', this.mobileViewService.isMobileViewEnabled());
      container.classList.add('mobile-view');
    } else {
      container.classList.remove('mobile-view');
    }
  }

  changeLanguage = (lang) => {
    localStorage.setItem("currentLang", lang);
    this.translate.setDefaultLang(lang);
  }

  // initiate iti Object
  telInputObject(obj) {
    this.iti = obj;
  }
  
  signup() {

    if(!this.verificationType) {
      this.dataShareService.showAlert({title: 'Error', body: 'Kindly choose verification Method to continue.'});
      return;
    }

    // this.mobile = this.iti ? this.iti.getNumber() : null;

    if(
      this.username.trim().length == 0 ||
      this.email.trim().length == 0 ||
      this.password.trim().length == 0 ||
      this.cpassword.trim().length == 0 || 
      (this.verificationType == "mobile" && this.mobile.trim().length == 0)
    ) {
      this.dataShareService.showAlert({title: 'Error', body: 'Please fill all required information to continue.'});
      return;
    }

    const regex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!regex.test(this.email)) {
      this.dataShareService.showAlert({title: 'Error', body: 'Enter valid email address'});
      return;
    }

    if(this.password != this.cpassword) {
      this.dataShareService.showAlert({title: 'Error', body: 'Password and confirm password must match.'});
      return;
    }
  
    let userSignup = {
      username        : this.username,
      password        : this.password,
      email           : this.email,
      mobile          : this.mobile,
      verificationType: this.verificationType  
    }

    this.dataShareService.startSpinner('Signing you up ...');
    this.accountService.signupUser(userSignup).subscribe(res => {
      this.dataShareService.stopSpinner();

      if(res.success) {
        if(res.mobileVerification) {
          this.auth_token = res.auth_token;
          this.dataShareService.showAlert({title: 'Success', body: 'Your account is created. Kindly enter an OTP sent to your number to verify your account.'});
          this.showOTPInput = true;
        } else {
          this.dataShareService.showAlert({title: 'Success', body: 'A verification email is sent sucessfully! Please follow the instructions to verify your email.'});
          this.router.navigate(['/login']);
          return;
        }
      } else {
        if(res.error == "duplicate_email") {
          this.dataShareService.showAlert({title: 'Error', body: 'Email ID already registered! Please enter different email to register.'});
          return;
        } if(res.error == "duplicate_mobile") {
          this.dataShareService.showAlert({title: 'Error', body: 'Mobile already registered! Please enter different mobile to register.'});
          return;
        } else {
          this.dataShareService.showAlert({title: 'Error', body: 'Something went wrong! Please try again.'});
          return;
        }
      }
    },
    err => {  
      if(err.status == 429) {
        this.dataShareService.showAlert({title: 'Error', body: 'You have reached signup request limit. Please try again later after an hour.'});
      }
    });
  }

  verifyOTP() {
    if(this.OTP.trim().length == 0) {
      this.dataShareService.showAlert({title: 'Error', body: 'Please enter OTP to continue.'});
      return;
    }

    this.accountService.verifyOTP({email: this.email, OTP: this.OTP}, this.auth_token).subscribe(res => {
      if(res.success) {
        this.dataShareService.showAlert({title: 'Success', body: 'OTP is successfully Verified. You can proceed with Login now.'});
        this.auth_token = "";
        this.router.navigate(['/login']);
        return;
      } else if(res.error == "invalid_otp") {
        this.dataShareService.showAlert({title: 'Error', body: 'The OTP you entered is Invalid. Kindly check again.'})
      } else {
        this.dataShareService.showAlert({title: 'Error', body: 'Something went wrong! Please try again.'})
      }
    })
  }

  resendOTP() {
    this.mobile = this.iti ? this.iti.getNumber() : null;

    this.accountService.resendOTP({email: this.email, mobile: this.mobile}, this.auth_token).subscribe(res => {
      if(res.success) {
        this.dataShareService.showAlert({title: 'Error', body: 'A new OTP has been sent to your mobile number. Kindly enter new OTP to verify your account.'});
        return;
      } else if(res.error == "sms_Error") {
        this.dataShareService.showAlert({title: 'Error', body: 'There was an issue sending an OTP to your mobile. Please try again.'});
        return;
      } else {
        this.dataShareService.showAlert({title: 'Error', body: 'Something went wrong! Please try again.'});
        return;
      }
    })
  }

}
