// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxElectronModule } from 'ngx-electron';
import { HotkeyModule } from 'angular2-hotkeys';
import { Ng2CompleterModule } from "ng2-completer";
import { DataTableModule } from "angular-6-datatable";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../environments/environment'

// Services
import { TradingService } from './services/trading.service';
import { TourGuideService } from './services/tour-guide.service';
import { AuthGuard } from './guards/auth.guard';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { HistoryComponent } from './components/history/history.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { CapitalComponent } from './components/capital/capital.component';
import { AiTradingComponent } from './components/ai-trading/ai-trading.component';
import { HeaderComponent } from './components/header/header.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { ChartComponent } from './components/chart/chart.component';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';
import { SignupComponent } from './components/signup/signup.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AlertComponent } from './components/alert/alert.component';
import { GetstartedComponent } from './components/getstarted/getstarted.component';

// routes
const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'signup', component: SignupComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard] },
  { path: 'wallet', component: WalletComponent, canActivate:[AuthGuard] },
  { path: 'history', component: HistoryComponent, canActivate:[AuthGuard] },
  { path: 'portfolio', component: PortfolioComponent, canActivate:[AuthGuard] },
  { path: 'capital', component: CapitalComponent, canActivate:[AuthGuard] },
  { path: 'trading', component: AiTradingComponent, canActivate:[AuthGuard] },
  { path: 'config', component: ConfigurationComponent, canActivate:[AuthGuard] },
  { path: 'config/:id', component: ConfigurationComponent, canActivate:[AuthGuard] },
  { path: 'manageAc', component: ManageAccountComponent, canActivate:[AuthGuard] },
  // { path: 'chart/:sym', component: ChartComponent, canActivate:[AuthGuard] },
  { path: 'chart/:sym', component: ChartWidgetComponent, canActivate:[AuthGuard] },
]

// socket config
const config: SocketIoConfig = { url: environment.socketURL, options: {} };

// loading local language translation data
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    WalletComponent,
    HistoryComponent,
    PortfolioComponent,
    CapitalComponent,
    AiTradingComponent,
    HeaderComponent,
    ConfigurationComponent,
    ManageAccountComponent,
    ChartComponent,
    ChartWidgetComponent,
    SignupComponent,
    SpinnerComponent,
    AlertComponent,
    GetstartedComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(appRoutes),
    SocketIoModule.forRoot(config),
    HotkeyModule.forRoot(),
    NgxElectronModule,
    HttpClientModule,
    Ng2CompleterModule,
    DataTableModule,
    Ng2TelInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiURL + '/logs', 
      level: environment.logLevel, 
      serverLogLevel: environment.serverLogLevel
    })
  ],
  providers: [
    TradingService,
    TourGuideService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
