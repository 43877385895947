import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
declare const TradingView: any;

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.css']
})
export class ChartWidgetComponent implements OnInit, AfterViewInit  {

  sym;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.sym = params.get("sym");
    })
  }

  ngAfterViewInit() {
    new TradingView.widget({
       'container_id': 'tradingview-charting-widget',
       'autosize': true,
       'symbol': this.sym ? this.sym.toUpperCase() : 'AAPL',
       'interval': 'D',
       'timezone': 'exchange',
       'theme': 'Dark',
       'style': '1',
       'toolbar_bg': '#f1f3f6',
       'withdateranges': true,
       'hide_side_toolbar': false,
       'allow_symbol_change': true,
       'save_image': false,
       'hideideas': true
     });
   }

}
