import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { BehaviorSubject, from } from 'rxjs';

declare var cordova;

@Injectable({
  providedIn: 'root'
})

export class DataShareService {

  private alertSource = new BehaviorSubject({title: '', body: '', translationParams: {}});
  alert = this.alertSource.asObservable();

  private noticeSource = new BehaviorSubject({show: false});
  notice = this.noticeSource.asObservable();
  
  private spinnerSource = new BehaviorSubject({show: false, loadingText: ''});
  spinner = this.spinnerSource.asObservable();

  constructor(private electronService: ElectronService) { }

  showAlert = (alertData) => {
    this.alertSource.next(alertData);
  }

  showNotice = () => {
    this.noticeSource.next({show: true});
  }

  startSpinner= (loadingText) => {
    this.spinnerSource.next({show: true, loadingText: loadingText});
  }

  stopSpinner= () => {
    this.spinnerSource.next({show: false, loadingText: ''});
  }

  isAMobilePlatform = () => {
    return typeof(cordova) == "object";
  }

  openLink = (url) => {
    if(this.isAMobilePlatform()) {
      console.log('Opening Link in Mobile:', url);
      cordova.InAppBrowser.open(url, "_system");
    } else {
      console.log('Opening Link in Desktop:', url);
      this.electronService.shell.openExternal(url);
    }
  }
}
