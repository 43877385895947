import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as introJs from 'intro.js/intro.js';

@Injectable({
  providedIn: 'root',
})
export class TourGuideService {

  introJS = introJs();
  translations;

  constructor(private router: Router, private translateService: TranslateService) { 
    console.log('Tour service loaded..');
  }

  ngOnInit() {
  }

  async start(section) {
    // update translation
    await this.getTranslation();

    // dashboard tour
    if(section == "dashboard") {
      console.log('Starting dashboard tour..');

      if(this.router.url != "/dashboard") {
        await this.router.navigate(['/dashboard']);
      }
      
      this.introJS.setOptions({
        skipLabel: this.translations["skip"] || "Skip",
        doneLabel: this.translations["exit"] || "Exit",
        nextLabel: this.translations["next"] || "Next",
        prevLabel: this.translations["prev"] || "Prev",
        steps: [
          { 
            intro: this.translations["welcome to tour!"]
          },
          {
            element: document.querySelector('#trading'),
            intro: this.translations["this is a trade section from where you can buy/sell stocks by placing market & auto trading order."]
          },
          {
            element: document.querySelector('#portfolio'),
            intro: this.translations["this is a portfolio section where you can track of your current stock holdings."]
          },
          {
            element: document.querySelector('#wallet'),
            intro: this.translations["this is a wallet section where you can track of your current balances."]
          },
          {
            element: document.querySelector('#capital'),
            intro: this.translations["this is a capital section where you can track of your capital value."]
          },
          {
            element: document.querySelector('#history'),
            intro: this.translations["this is a history section where you can see your detailed previous trading history."]
          }
        ]
      });
  
      this.introJS.start();
    }
  }

  getTranslation() {
    return new Promise((resolve, reject) => {
      this.translateService.getTranslation(localStorage.getItem("currentLang") || 'en').subscribe(data => {
        this.translations = data;
        resolve();
      }, error => {
        reject(error);
      });
    });
    
  }
}
