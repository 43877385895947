import { Injectable } from '@angular/core';
import { JwtHelperService  } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  auth_token;

  constructor() { }

  storeUserData(data) {
    localStorage.setItem('auth_token',data.token);
    localStorage.setItem("auth_user", JSON.stringify(data.user));
    this.auth_token = data.token;
  }

  loadToken() {
    this.auth_token = localStorage.getItem('auth_token');
    return this.auth_token;
  }

  loggedIn() {
    this.loadToken();
    return !(new JwtHelperService().isTokenExpired(this.auth_token));
  }

  logout() {
    localStorage.setItem("rows", "[]");
    localStorage.setItem("wallets", "[]");
    localStorage.setItem("portfolio", "[]");
    localStorage.setItem("capital", "[]");
    localStorage.setItem("history", "[]");
    localStorage.setItem("notificationEnabled", "false");
    localStorage.removeItem("hotKeys");
    localStorage.removeItem("auth_token");
  }
}
