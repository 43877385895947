import { Component, OnInit } from '@angular/core';
import { MobileViewService } from '../../services/mobile-view.service'; // Adjust the path as necessary

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(public mobileViewService: MobileViewService) { // Inject the service here
  }

  ngOnInit() {
    
    this.applyMobileView();
  }

  applyMobileView() {
    const container = document.querySelector('.container');
    if (this.mobileViewService.isMobileViewEnabled()) {
      console.log('Mobile view enabled:', this.mobileViewService.isMobileViewEnabled());
      //container.classList.add('mobile-view');
    } else {
      console.log("Mobile Disable")
      //container.classList.remove('mobile-view');
    }
  }

  
}

