import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  URL = environment.apiURL;

  constructor( private http: Http, private authService: AuthService ) { }

  login(user) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    return this.http.post(this.URL+'/login',user,{headers:headers})
    .pipe(map(res=>res.json()));
  } 

  signupUser(user) {
    let headers = new Headers();
    return this.http.post(this.URL+'/register',user,{headers:headers})
    .pipe(map(res=>res.json()));
  }

  walletInfo(){
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.get(this.URL+'/wallets',{headers:headers})
    .pipe(map(res => {
      let data = res.json();

      if(!data.error) {
        console.log('Successfully fetched wallet info!');

        // 2 decimals
        for(var i = 0; i < data.length; i++) {
          data[i].silver_usd = Math.floor(data[i].silver_usd * 100) / 100;
        }
      }
      
      return data;
    }));
  }
  
  history(){
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.get(this.URL+'/history',{headers:headers})
    .pipe(map(res=>res.json()));
  }

  getPosition(symbol, address) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.get(this.URL+'/position/'+symbol+'/'+address,{headers:headers})
    .pipe(map(res=>res.json()));
  }

  getCapital(address) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    

    return new Promise((resolve, reject) => {
      this.http.get(this.URL+'/capital/'+address,{headers:headers})
      .pipe(map(res=>res.json()))
      .subscribe(data => {
        if(data.success) {
          resolve(data.balance);
        } else {
          reject(data.error);
        }
      })
    })
    
  }

  getUSDBalance(address) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    

    return new Promise((resolve, reject) => {
      this.http.get(this.URL+'/USD/'+address,{headers:headers})
      .pipe(map(res=>res.json()))
      .subscribe(data => {
        if(data.success) {
          let balance = Math.floor(data.balance * 100) / 100;
          console.log('USD Balance:', balance, 'wallet address:', address);
          resolve(balance);
        } else {
          reject(data.error);
        }
      })
    })
    
  }

  sendNotification(body) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.post(this.URL+'/notification',body, {headers:headers})
    .pipe(map(res=>res.json()));
  }

  verifyOTP(data, auth_token) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('Authorization', auth_token); 
    return this.http.post(this.URL+'/verifyOTP',data,{headers:headers})
    .pipe(map(res=>res.json()));
  }

  resendOTP(data, auth_token) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('Authorization', auth_token); 
    return this.http.post(this.URL+'/resendOTP',data,{headers:headers})
    .pipe(map(res=>res.json()));
  }

  getWalletStatus(wallet_address) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.get(this.URL+'/wallets/'+wallet_address+'/status', {headers:headers})
    .pipe(map(res=>res.json()));
  }

  getFeatureStatus(featureid) {
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let auth_token = this.authService.loadToken();
    headers.append('Authorization', auth_token);    
    return this.http.post(this.URL+'/features/status', {featureid}, {headers:headers})
    .pipe(map(res=>res.json()));
  }
}
