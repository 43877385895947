import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { DataShareService } from '../../services/data-share.service';
import { MobileViewService } from '../../services/mobile-view.service';
import { UserService } from '../../services/user.service';


import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email;
  password;
  remember = false;
  openLink: Function;
  isLargeScreen: boolean = window.innerWidth > 767;
  isLoading = false;
  wallets = [];
  wallet_balance: any;
  isMobileView = false;
  user_id;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private accountService: AccountService,
    private dataShareService: DataShareService,
    private router: Router,
    private socket: Socket,
    private mobileViewService: MobileViewService,
    private userService: UserService,
  ) {
    this.openLink = dataShareService.openLink;
  }

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.router.navigate(['/dashboard']);
    } else {
      let user = JSON.parse(localStorage.getItem("auth_user"));

      if (user && user.remember) {
        this.email = user.email || "";
        this.remember = true;
      }
    }

  }


  toggleMobileView() {
    if (this.mobileViewService.isMobileViewEnabled()) {
      this.mobileViewService.disableMobileView();
    } else {
      this.mobileViewService.enableMobileView();
    }
    this.updateView();
  }

  updateView() {
    const container = document.querySelector('.container');
    if (this.mobileViewService.isMobileViewEnabled()) {
      container.classList.add('mobile-view');
    } else {
      container.classList.remove('mobile-view');
    }
  }



  changeLanguage = (lang) => {
    localStorage.setItem("currentLang", lang);
    this.translate.setDefaultLang(lang);
  }

  login = () => {
    let credentials = {
      email: this.email,
      password: this.password
    }

    this.dataShareService.startSpinner('Logging in...');
    this.accountService.login(credentials).subscribe(data => {
      this.dataShareService.stopSpinner();
      if (data.success) {
        data.user.remember = this.remember;
        this.authService.storeUserData(data);
        this.socket.connect();
        localStorage.setItem("wallets", "[]");
        const user_data = JSON.parse(localStorage.getItem("auth_user"));
        this.user_id = user_data.id;
        console.log('user id:', this.user_id);
        this.getUserWalletsBalance();
        this.router.navigate(['dashboard']);
        console.log(data);

        // show notice every time
        this.dataShareService.showNotice();
      }
      else {
        if (data.error == "invalid_password") {
          this.dataShareService.showAlert({ title: 'Error', body: 'Invalid Password!' });
          return;
        }
        if (data.error == "invalid_email") {
          this.dataShareService.showAlert({ title: 'Error', body: 'Invalid Email!' });
          return;
        }
        if (data.error == "email_notverified") {
          this.dataShareService.showAlert({ title: 'Error', body: 'Your account is not verified! Please follow the email instructions to activate your account.' });
        }
        else {
          this.dataShareService.showAlert({ title: 'Error', body: 'Something went wrong! Please try again.' });
          return;
        }
      }
    }, error => {
      this.dataShareService.stopSpinner();
      console.log(error);
      this.dataShareService.showAlert({ title: 'Error', body: 'Something went wrong! Please try again.' });
    });
  }

  getUserWalletsBalance() {
    this.isLoading = true;
    this.userService.getUserWalletsBalance(this.user_id).subscribe(data => {
      this.wallet_balance = data; // Assign the response data to wallet_balance
      console.log('Received wallet balance data:', this.wallet_balance);
      this.updateWalletBalance(); // Call updateWalletBalance to update the wallet table
      this.isLoading = false;
    }, error => {
      console.error('Error fetching wallet balances:', error);
      this.isLoading = false;
    });
  }

  updateWalletBalance() {
    if (!this.wallet_balance) {
      console.error('No wallet balance data available');
      return;
    }
  
    // Clear the current wallets array
    this.wallets = [];
  
    // Loop through the keys in the backend response and create wallet entries
    for (let walletName in this.wallet_balance) {
      console.log('hn' , this.wallet_balance[walletName])
      if (this.wallet_balance.hasOwnProperty(walletName)) {
        this.wallets.push({
          id: this.wallet_balance[walletName].id,
          wallet_name: walletName,
          silver_usd: this.wallet_balance[walletName].balance,
          wallethash: this.wallet_balance[walletName].wallethash, // Access wallethash
          islocked: this.wallet_balance[walletName].islocked, // Access islocked
          lockedtime: this.wallet_balance[walletName].lockedtime
        });
      }
    }
  
    // Save the updated wallets to localStorage
    localStorage.setItem('wallets', JSON.stringify(this.wallets));
    console.log('Updated wallets:', this.wallets);
  }



}