import { Component, OnInit } from '@angular/core';
import { TradingService } from '../../services/trading.service';

@Component({
  selector: 'app-capital',
  templateUrl: './capital.component.html',
  styleUrls: ['./capital.component.css']
})
export class CapitalComponent implements OnInit {

  wallets;
  wallet_id;
  data = [];

  // total value vars
  wallet_balance;
  wallet_position;

  constructor(
    private tradingService: TradingService
  ) { }

  ngOnInit() {
    this.wallets = JSON.parse(localStorage.getItem("wallets"));
  }

  async calculateUPL() {
    let portfolio = JSON.parse(localStorage.getItem("portfolio")).find(x => x.wallet_id == this.wallet_id);

    if (portfolio) {
      this.data = [];
      for (var i = 0; i < portfolio.data.length; i++) {
        let currentPrice: any;
        try {
          currentPrice = await this.tradingService.fetchStockPrice('NYSE', portfolio.data[i].symbol);
          console.log('Current price NYSE:' + currentPrice + ' for symbol:' + portfolio.data[i].symbol)
        }
        catch (e) {
          try {
            console.log('No price found for NYSE. Trying TSE for symbol:' + portfolio.data[i].symbol);
            currentPrice = await this.tradingService.fetchStockPrice('TSE', portfolio.data[i].symbol);
            console.log('Current price TSE:' + currentPrice + ' for symbol:' + portfolio.data[i].symbol)
          }
          catch (e) {
            try {
              console.log('No price found for TSE. Trying SHE for symbol:' + portfolio.data[i].symbol);
              currentPrice = await this.tradingService.fetchStockPrice('SHE', portfolio.data[i].symbol);
              console.log('Current price SHE:' + currentPrice + ' for symbol:' + portfolio.data[i].symbol)
            }
            catch (e) {
              try {
                console.log('No price found for SHE. Trying SHA for symbol:' + portfolio.data[i].symbol);
                currentPrice = await this.tradingService.fetchStockPrice('SHA', portfolio.data[i].symbol);
                console.log('Current price SHA:' + currentPrice + ' for symbol:' + portfolio.data[i].symbol)
              }
              catch (e) {
                try {
                  console.log('No price found for SHA. Trying SEHK for symbol:' + portfolio.data[i].symbol);
                  currentPrice = await this.tradingService.fetchStockPrice('SEHK', portfolio.data[i].symbol);
                  console.log('Current price SEHK:' + currentPrice + ' for symbol:' + portfolio.data[i].symbol)
                }
                catch (e) {
                  console.log('error while fetching price');
                  currentPrice = 0;
                }
              }
            }
          }
        }

        this.data.push({
          symbol: portfolio.data[i].symbol,
          UPL: portfolio.data[i].position > 0 ?
            (currentPrice - portfolio.data[i].avg_price) * portfolio.data[i].position :
            (portfolio.data[i].avg_price - currentPrice) * Math.abs(portfolio.data[i].position)
        })
      }

      this.updateLocalData({
        wallet_id: portfolio.wallet_id,
        data: this.data
      });
    }

  }

  calculateCapitalValues() {
    // reset values
    this.wallet_balance = 0;
    this.wallet_position = 0;

    let portfolio = JSON.parse(localStorage.getItem("portfolio")).find(x => x.wallet_id == this.wallet_id);

    if (portfolio) {
      // get wallet balanace
      this.wallet_balance = this.wallets.find(x => x.id == this.wallet_id).silver_usd;
      
      // get sum of mkt_value
      this.wallet_position = portfolio.data.reduce((total, obj) => { return total + obj.mkt_value }, 0);
    }
  }

  getLocalData() {
    var capital = JSON.parse(localStorage.getItem("capital"));

    for (let i = 0; i < capital.length; i++) {
      if (capital[i].wallet_id == this.wallet_id) {
        console.log('Getting local copy of capital for wallet_id:' + this.wallet_id);
        this.data = capital[i].data;
        return;
      }
    }

    this.data = [];
  }

  updateLocalData(capitalData) {
    var capital = JSON.parse(localStorage.getItem("capital"));

    // if data exists
    if (capital.length > 0) {
      for (let i = 0; i < capital.length; i++) {
        if (capital[i].wallet_id == capitalData.wallet_id) {
          // update data if exists
          capital[i].data = capitalData.data;
          localStorage.setItem("capital", JSON.stringify(capital));
          return;
        }
      }
      // push data if doesn't exist
      capital.push(capitalData);
    }
    else {
      capital.push(capitalData);
    }

    localStorage.setItem("capital", JSON.stringify(capital));
  }

}
