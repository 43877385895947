import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    URL = environment.apiURL;

    uploadURL = environment.apiURL;

    constructor(private http: Http, private authService: AuthService) { }

    getWalletDetailsByUserId(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
    
        return this.http.get(this.URL + '/' + userId + '/wallet-details', { headers: headers })
            .pipe(
                map(res => res.json()),
                catchError(error => {
                    console.error('Error fetching wallet details:', error);
                    return throwError(error);
                })
            );
    }    

    generateWallet(currentStatementPath: string, previousStatementPath: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());

        const body = JSON.stringify({
            currentStatementPath: currentStatementPath,
            previousStatementPath: previousStatementPath,
        });

        return this.http.post(this.URL + '/generate-wallet-balance', body, { headers: headers })
            .pipe(
                map(response => response.json()),
                catchError(error => {
                    console.error('Error generating Wallet Balance:', error);
                    return throwError(error);
                })
            );
    }

    getFundTransfersByUserId(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/fundtransferbyId', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserWallet(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/wallets', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserWalletsBalance(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/b-wallets-balance', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserDetails(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/profile', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getHistory() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/all/history', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getWalletHistory(walletId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/wallets/' + walletId + '/history', { headers: headers })
            .pipe(map(res => res.json()));
    }

}
