import { Component, OnInit, OnDestroy } from '@angular/core';
import { TradingService } from '../../services/trading.service';
import { MobileViewService } from '../../services/mobile-view.service'; // Adjust the path as necessary
import { Socket } from 'ngx-socket-io';
import { UserService } from '../../services/user.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit, OnDestroy {

  isLoading = false;
  wallets = [];
  wallet_balance: any;
  isMobileView = false;
  user_id; 

  constructor(
    public mobileViewService: MobileViewService,
    private userService: UserService,
    private socket: Socket 
  ) { 
    const user_data = JSON.parse(localStorage.getItem("auth_user"));
    this.user_id = user_data.id;
    console.log('user id:' , this.user_id);
  }

  ngOnInit() {
    this.wallets = JSON.parse(localStorage.getItem("wallets"));
    this.applyMobileView();

    this.getUserWalletsBalance();

  }

  applyMobileView() {
    const container = document.querySelector('.container');
    if (this.mobileViewService.isMobileViewEnabled()) {
      console.log('Mobile view enabled wallet:', this.mobileViewService.isMobileViewEnabled());
      //container.classList.add('mobile-view');
    } else {
      console.log("Mobile Disable")
      //container.classList.remove('mobile-view');
    }
  }

  ngOnDestroy() {
    this.socket.removeAllListeners('wallet-balance');
    this.socket.removeAllListeners('wallet-creation');
  }

  // Fetch wallet balances for the user from backend
  getUserWalletsBalance() {
    this.isLoading = true;
    this.userService.getUserWalletsBalance(this.user_id).subscribe(data => {
      this.wallet_balance = data; // Assign the response data to wallet_balance
      console.log('Received wallet balance data:', this.wallet_balance);
      this.updateWalletBalance(); // Call updateWalletBalance to update the wallet table
      this.isLoading = false;
    }, error => {
      console.error('Error fetching wallet balances:', error);
      this.isLoading = false;
    });
  }

updateWalletBalance() {
  if (!this.wallet_balance) {
    console.error('No wallet balance data available');
    return;
  }

  // Clear the current wallets array
  this.wallets = [];

  // Loop through the keys in the backend response and create wallet entries
  for (let walletName in this.wallet_balance) {
    console.log('hn' , this.wallet_balance[walletName])
    if (this.wallet_balance.hasOwnProperty(walletName)) {
      this.wallets.push({
        id: this.wallet_balance[walletName].id,
        wallet_name: walletName,
        silver_usd: this.wallet_balance[walletName].balance,
        wallethash: this.wallet_balance[walletName].wallethash, // Access wallethash
        islocked: this.wallet_balance[walletName].islocked, // Access islocked
        lockedtime: this.wallet_balance[walletName].lockedtime
      });
    }
  }

  // Save the updated wallets to localStorage
  localStorage.setItem('wallets', JSON.stringify(this.wallets));
  console.log('Updated wallets:', this.wallets);
}
  

}
