import { Component, OnInit, HostListener, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from './services/account.service';
import { TradingService } from './services/trading.service';
import { ElectronService } from 'ngx-electron';
import { Socket } from 'ngx-socket-io';
import { DataShareService } from './services/data-share.service';
import { AuthService } from './services/auth.service';

declare var navigator;
declare var cordova;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  // auto update
  downloadPercent = 0;

  currentLang;
  isStopped = false;
  
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private translate: TranslateService,
    private tradingService: TradingService,
    private dataShareService: DataShareService,
    private socket: Socket,
    private electronService: ElectronService,
    private router: Router,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {


    // Socket Events
    this.socket.on('connect', () => {
      
      console.log('Socket connected');

      let auth_token = localStorage.getItem("auth_token");
      // send auth request
      if(auth_token) {
        console.log('Sending auth request for socket');
        this.socket.emit('authentication', {
          auth_token: auth_token
        });
      }

      localStorage.setItem("socketStatus", "connected");
    });
  
    this.socket.on('unauthorized', (reason) => {
      console.log('Socket unauthorized:', reason);
  
      this.socket.disconnect();
    });
  
    this.socket.on('disconnect', (reason) => {
      let notificationEnabled = localStorage.getItem("notificationEnabled") === "true" ? true : false;

      console.log('Socket disconnected:', reason);
      localStorage.setItem("socketStatus", "disconnected");

      if(reason == "old session" && notificationEnabled) {
        this.accountService.sendNotification({type: "socket_connection"}).subscribe(res => {
          if(res.success) {
            console.log('Notification sent successfully for socket_connection.');
          } else {
            console.log('Error while sending the notification: ', res.error);
          }
        })
      }
    });

    socket.on('message', (data) => {       
      if(data.message == 'No Capital') {
        this.dataShareService.showAlert({title: 'Error', body: 'You have insufficient balance to execute transaction!'});
      }   
    })  
    
  }


  ngOnInit() { 
    // set default to english
    if(!localStorage.getItem('currentLang')) {
      localStorage.setItem('currentLang', 'en')
    }

    // setting language of preference
    this.currentLang = localStorage.getItem('currentLang') || 'en';
    this.translate.setDefaultLang(this.currentLang);

    if(!localStorage.getItem("rows")) {
      localStorage.setItem("rows", "[]");
    }

    if(!localStorage.getItem("wallets")) {
      localStorage.setItem("wallets", "[]");
    }

    if(!localStorage.getItem("history")) {
      localStorage.setItem("history", "[]");
    }

    if(!localStorage.getItem("portfolio")) {
      localStorage.setItem("portfolio", "[]");
    }

    if(!localStorage.getItem("capital")) {
      localStorage.setItem("capital", "[]");
    }

    document.addEventListener("deviceready", this.onDeviceReady, false);


    // Auto Update Events for Electron
    this.electronService.ipcRenderer.on('autoUpdate', (event, args) => {

      // downloading progress
      if(args.status == "progress") {
        this.downloadPercent = args.data.percent;
        this.dataShareService.startSpinner('Please wait. Updating App - '+ this.downloadPercent.toFixed(2) + '%')
        if(this.downloadPercent == 100) {
          this.dataShareService.stopSpinner();
        }
        this.cd.detectChanges();
      }

      if(args.status == "error") {
        this.dataShareService.showAlert({title: 'Error', body: 'Error while updating the App!'});
        // this.electronService.remote.getCurrentWindow().close();
      }

      console.log('args:', args);
    })
  }

  onDeviceReady = () => {
    console.log(cordova.platformId + ' device is Ready!');
    
    document.addEventListener("backbutton", (event) => {
      if(this.router.url == '/dashboard') {
        this.beforeUnload(event);
      }
      else if(this.router.url == '/login') {
        navigator.app.exitApp();
      } 
      else {
        this.ngZone.run(() => {this.router.navigate(['/dashboard']);});
      }
    
   }, false);
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeunloadHandler(event) {
    if(this.authService.loggedIn()) {
      console.log('Taking care of things before dying ...');
      this.beforeUnload(event);
    }
  }


  beforeUnload = async (event) => {
    let rows = JSON.parse(localStorage.getItem("rows"));
    const hasAutoTrading = rows.filter(row => row.lastOrderType === "Auto Trading").length > 0 ? true : false;

    if(!this.isStopped){
      // prevent window from closing
      event.returnValue = false;
    }
    
    if(hasAutoTrading) {
      // show agreement
      document.getElementById("agreementbtn").click();
    } else {
      this.stopSignalOnClose();
    }   
  }

  async onAgreement () {
    this.stopSignalOnClose();
  } 

  async stopSignalOnClose() {
    console.log('Stopping signal on close ...');

    this.dataShareService.startSpinner('Closing ...') 
    // wait and stop the signals
    await this.tradingService.stopSignalOnClose();
    // logout
    if(environment.production) {
      this.authService.logout();
      // this.router.navigate(['/login']);
    } 
    this.isStopped = true;
    // close the terminal
    if(this.electronService.remote) {
      this.electronService.remote.getCurrentWindow().close();
    }
    // close android app
    if(navigator.app) {
      navigator.app.exitApp();
    }
    this.dataShareService.stopSpinner();
  }

}